/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { getRequestWithToken, postRequestWithToken } from "sagas/api";
import create from "zustand";
import { store } from "store";
import { getToken } from "./modules/auth/selectors";
import { getCurrentUser } from "./modules/users/selectors";

export enum VerificationStatus {
  UNVERIFIED = "unverified",
  PROCESSING = "processing",
  VERIFIED = "verified",
  FAILED = "failed",
  VERIFIED_MANUAL = "verified_manual",
  PENDING_VERIFICATION = "pending_verification",
  IN_PROGRESS = "in_progress"
}

interface StripeState {
  verificationStatus: VerificationStatus | null;
  verificationId: string | null;
  sessionToken: string | null;
  isLoading: boolean;
  error: string | null;
  fetchVerificationStatus: (agencyKey?: string) => Promise<void>;
  initiateVerification: () => Promise<void>;
  getIsVerificationLoading: () => boolean;
  handleEmailVerification: () => Promise<number>;
  retrySeconds: number;
  resetVerification: () => void;
}

const useVerificationStore = create<StripeState>(set => ({
  verificationStatus: process.env.REACT_APP_PERSONA_ENABLED === "true" ? null : VerificationStatus.VERIFIED,
  verificationId: null,
  sessionToken: null,
  isLoading: false,
  error: "",
  retrySeconds: 0,

  initiateVerification: async () => {
    const agencyKey = getCurrentUser(store.getState())?.key;
    const token = getToken(store.getState());
    if (!token || !agencyKey) return;

    set({ isLoading: true, error: null });
    try {
      const response = await postRequestWithToken({
        url: "/api/agency/identity-verification/",
        token,
        payload: { agency: agencyKey }
      });

      set({
        verificationId: response.data.verification_id,
        sessionToken: response.data.session_token
      });
    } catch (error) {
      console.error("Error initiating verification:", error);
      set({ error: "Failed to initiate verification" });
    } finally {
      set({ isLoading: false });
    }
  },

  fetchVerificationStatus: async agencyKey => {
    if (process.env.REACT_APP_PERSONA_ENABLED !== "true") return;
    const token = getToken(store.getState());
    agencyKey = agencyKey ?? getCurrentUser(store.getState())?.key;
    if (!token || !agencyKey) return;

    set({ isLoading: true, error: null });
    try {
      const response = await getRequestWithToken({
        url: `/api/agency/${agencyKey}/verification-status/`,
        token
      });

      set({ verificationStatus: response.data.verification_status });
    } catch (error) {
      set({ error: "Failed to fetch verification status", verificationStatus: VerificationStatus.UNVERIFIED });
    } finally {
      set({ isLoading: false });
    }
  },

  resetVerification: () => {
    set({
      verificationId: null,
      sessionToken: null
    });
  },

  handleEmailVerification: async () => {
    const token = getToken(store.getState());
    if (!token) return 0;

    try {
      const response = await postRequestWithToken({
        url: "/api/auth/send-verification/",
        token
      });

      set({ retrySeconds: response.data.retry_after as number });
      return response?.data?.retry_after ?? 0;
    } catch (error) {
      console.error("Error sending verification email:", error);
      return 0;
    }
  },
  getIsVerificationLoading: ():boolean => useVerificationStore.getState().isLoading ||
    useVerificationStore.getState().verificationStatus === null
}));

export default useVerificationStore;
