import { Editor } from "@tinymce/tinymce-react";
import React, { useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import QuestionAutosuggest from "components/Job/QuestionsComponent/QuestionAutosuggest";
import { BeatLoader } from "react-spinners";
import { IDV_TYPE, RTW_TYPE } from "configs/jobs/constants";

const QuestionTitleEditor = ({
  isActive,
  order,
  fetchSuggestions,
  suggestions
}) => {
  const ref = useRef(null);
  const editorRef = useRef(null);
  const {
    control,
    setValue,
    watch,
    formState: { errors, touchedFields }
  } = useFormContext();
  const title = watch(`questions[${order - 1}].html_text`);
  const answerType = watch(`questions[${order - 1}].answer_type`);

  const [isEditorReady, setIsEditorReady] = useState(() => typeof window !== "undefined" && window.tinymce !== undefined);

  const convertToPlain = html => {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;

    return tempDivElement.textContent || tempDivElement.innerHTML || "";
  };

  const hasError = useMemo(() => errors?.questions?.[order - 1]?.text?.message
    && (touchedFields?.questions?.[order - 1]?.text),
  [errors.questions, order, touchedFields.questions]);

  return (
    <>
      <div
        className="active-question__title-container"
        key={`itemEditor-${order}`}
        ref={ref}
      >
        <span className="question__order n-font-medium-weight n-font-small">
          {order}
        </span>

        <div
          className={`question__title-input ${isActive ? "active" : ""} ${hasError ? "error" : ""}`}
        >
          {!isEditorReady && (
            <div className="text-editor-loader">
              <BeatLoader color="#5A2AF1" size={16} margin={1} loading />
            </div>
          )}

          <div
            style={{
              display: !isEditorReady ? "none" : "block",
              width: "100%"
            }}
          >
            <QuestionAutosuggest
              valueInput={title}
              suggestions={suggestions}
              fetchSuggestions={fetchSuggestions}
              onSuggestionSelected={suggestionValue => {
                setValue(
                  `questions[${order - 1}].html_text`,
                  `<p>${suggestionValue}</p>`
                );
                setValue(`questions[${order - 1}].text`, suggestionValue);
              }}
              onInputChanged={currentValue =>
                setValue(`questions[${order - 1}].text`, currentValue)
              }
              renderInputComponent={({
                onChange: inputPropOnChange,
                ...inputProps
              }) => (
                <Controller
                  name={`questions[${order - 1}].html_text`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref: inputReff } }) => (
                    <Editor
                      {...inputProps}
                      ref={editor => {
                        editorRef.current = editor;
                        if (inputReff) inputReff(editor);
                      }}
                      tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                      scriptLoading={{ async: true }}
                      value={value}
                      onInit={(_evt, editor) => {
                        if (editor) setIsEditorReady(true);
                      }}
                      init={{
                        min_height: 80,
                        width: "100%",
                        menubar: false,
                        statusbar: false,
                        content_style: `
                          body {
                            font-family: Poppins,sans-serif;
                            font-size:14px;
                          }

                          a {
                            color: #2AABE4;
                            text-decoration: underline
                          }

                          .mce-content-body, p {
                            margin: 0
                          }

                          .mce-content-body {
                            margin-left: 10px
                          }

                          .mce-content-readonly {
                            cursor: not-allowed;
                          }
                        `,
                        plugins: ["link", "autolink", "autoresize"],
                        toolbar: "bold italic underline link",
                        toolbar_location: "bottom",
                        contextmenu: "link",
                        link_context_toolbar: true,
                        link_assume_external_targets: true,
                        link_default_protocol: "https",
                        link_title: false,
                        link_default_target: "_blank",
                        link_target_list: [
                          { title: "New tab", value: "_blank" }
                        ],
                        extended_valid_elements: "a[href|target=_blank]",
                        powerpaste_word_import: "clean",
                        powerpaste_html_import: "clean",
                        autoresize_bottom_margin: 10,
                        paste_as_text: true
                      }}
                      onEditorChange={newValue => {
                        onChange(newValue);
                        inputPropOnChange({
                          target: { value: convertToPlain(newValue) }
                        });
                        setValue(
                          `questions[${order - 1}].text`,
                          convertToPlain(newValue),
                          {
                            shouldTouch: true,
                            shouldDirty: true,
                            shouldValidate: true
                          }
                        );
                      }}
                      onBlur={e => {
                        onBlur(e);
                        setTimeout(() => {
                          fetchSuggestions("");
                        }, 500);
                      }}
                      disabled={
                        answerType === IDV_TYPE || answerType === RTW_TYPE
                      }
                    />
                  )}
                />
              )}
            />
            {hasError && <span className="error-message">{errors?.questions?.[order - 1]?.text?.message}</span>}
          </div>
        </div>
      </div>
    </>
  );
};

QuestionTitleEditor.defaultProps = {
  isActive: undefined,
  order: undefined,
  suggestions: undefined,
  fetchSuggestions: undefined
};

QuestionTitleEditor.propTypes = {
  isActive: PropTypes.bool,
  order: PropTypes.number,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  fetchSuggestions: PropTypes.func
};

export default withTranslation()(QuestionTitleEditor);
