/* eslint-disable no-cond-assign */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import classNames from "classnames";
import TooltipInfo from "../Tooltip/Info";

const DEFAULT_BORDER_STYLE = "1px solid #BBC2C9";
const FOCUS_BORDER_STYLE = "1px solid #5A2AF1";
const ERROR_BORDER_STYLE = "1px solid #EB3323";


const Spam = ({ t, children, type, ...rest }) => (
  <TooltipInfo
    element={null}
    position="bottom"
    message={t(`libraries.tooltip.${type}`)}
  >
    <span {...rest} className={`${type}-words`}>
      {children}
    </span>
  </TooltipInfo>
);

Spam.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
const TextEditor = ({
  t,
  inputChangedHandler,
  invalid,
  withError,
  formElement: { label, value, touched, supLabel, supLabelValue },
  name,
  type,
  toolbarCustomButtons,
  withCounter,
  withSmsCounter,
  bottomText,
  toolbarOptions,
  editRef,
  LabelRight,
  reds,
  ambers,
  subLabelClassName,
  ...rest
}) => {
  const [wrapperStyle, setWrapperStyle] = useState({
    border: DEFAULT_BORDER_STYLE
  });

  const handleWrapperStyle = useCallback(style => {
    setWrapperStyle({
      border: invalid ? ERROR_BORDER_STYLE : style
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const redRegex = new RegExp((reds || []).join("|"), "gi");
  const amberRegex = new RegExp((ambers || []).join("|"), "gi");

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr;
    let start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const handleRedStrategy = useCallback(
    (contentBlock, callback) => findWithRegex(redRegex, contentBlock, callback),
    [redRegex]
  );

  const handleAmberStrategy = useCallback(
    (contentBlock, callback) => findWithRegex(amberRegex, contentBlock, callback),
    [amberRegex]
  );


  const compositeDecorator = useMemo(
    () => {
      const output = [];


      if (ambers.length > 0) {
        output.push({
          strategy: handleAmberStrategy,
          component: props => <Spam {...props} t={t} type="amber" />

        });
      }
      if (reds.length > 0) {
        output.push({
          strategy: handleRedStrategy,
          component: props => <Spam {...props} t={t} type="red" />

        });
      }
      return output;
    },
    [ambers.length, handleAmberStrategy, handleRedStrategy, reds.length, t]
  );

  useEffect(() => {
    if (invalid) {
      handleWrapperStyle(ERROR_BORDER_STYLE);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalid]);

  return (
    <div className={`text-editor ${withError ? "error" : ""}`}>
      <div className="label label__texteditor">
        {label ? (
          <span className="text-editor__label">{t(`textarea.${label}`)}</span>
        ) : null}
        {supLabel && (
          <span className={classNames("label__secondary", subLabelClassName)}>
            {t(`input.${[supLabel]}`, supLabelValue)}
          </span>
        )}
        {LabelRight && <LabelRight />}
      </div>

      <Editor
        {...rest}
        stripPastedStyles
        editorRef={editRef}
        editorState={value}
        trailingWhitespace={false}
        toolbar={{
          options: toolbarOptions,
          inline: {
            options: ["bold", "italic"],
            className: "text-editor__inline"
          },
          list: {
            options: ["unordered", "ordered"],
            className: "text-editor__list"
          },
          textAlign: {
            options: ["left", "center", "right"],
            className: "text-editor__align"
          },
          blockType: {
            inDropdown: true,
            options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            className: "text-editor__inline"
          },
          link: {
            inDropdown: false,
            showOpenOptionOnHover: false,
            defaultTargetOption: "_blank",
            options: ["link"]
          }
        }}
        localization={{
          locale: "en",
          translations: {
            "components.controls.link.linkTarget": "URL"
          }
        }}
        toolbarClassName="text-editor__toolbar"
        wrapperClassName="text-editor__main-body"
        editorClassName="text-editor__editor"
        wrapperStyle={{
          ...wrapperStyle,
          ...(withError ? { border: ERROR_BORDER_STYLE } : null)
        }}
        onBlur={event => {
          handleWrapperStyle(DEFAULT_BORDER_STYLE);
          if (typeof rest?.onBlur === "function") rest.onBlur(event);
        }}
        onEditorStateChange={event => {
          handleWrapperStyle(FOCUS_BORDER_STYLE);
          inputChangedHandler(event, name, type);
        }}
        toolbarCustomButtons={toolbarCustomButtons}
        withCounter={withCounter}
        withSmsCounter={withSmsCounter}
        bottomText={bottomText}
        key={reds?.length || 0 + ambers?.length || 0}
        customDecorators={((reds?.length || 0) + (ambers?.length || 0) > 0)
          ? compositeDecorator : undefined}
      />

      {invalid && touched && (
        <p className="text-editor__error">
          {t("textarea.jobDescriptionError")}
        </p>
      )}
    </div>
  );
};

TextEditor.defaultProps = {
  inputChangedHandler: undefined,
  formElement: undefined,
  invalid: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  toolbarCustomButtons: [],
  withCounter: false,
  withSmsCounter: false,
  bottomText: "",
  toolbarOptions: ["inline", "blockType", "list", "textAlign"],
  withError: false,
  LabelRight: null,
  editRef: undefined,
  ambers: [],
  reds: []
};

TextEditor.propTypes = {
  t: PropTypes.func.isRequired,
  formElement: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
    touched: PropTypes.bool,
    supLabel: PropTypes.string,
    supLabelValue: PropTypes.shape({})
  }),
  inputChangedHandler: PropTypes.func,
  invalid: PropTypes.bool,
  withError: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  toolbarCustomButtons: PropTypes.arrayOf(PropTypes.node),
  reds: PropTypes.arrayOf(PropTypes.string),
  ambers: PropTypes.arrayOf(PropTypes.string),
  withCounter: PropTypes.bool,
  withSmsCounter: PropTypes.bool,
  bottomText: PropTypes.string,
  toolbarOptions: PropTypes.arrayOf(PropTypes.string),
  editRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  LabelRight: PropTypes.node
};

export default withTranslation()(TextEditor);
