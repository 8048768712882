/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { takeEvery, select, call, put } from "redux-saga/effects";

import { isUsersLoading,
  setIsUsersAdded,
  fetchUsersList,
  fetchUsersListResponse,
  fetchUsersListError,
  fetchUsersResponse,
  fetchUsersError,
  addNewUsers,
  addNewUsersResponse,
  addNewUsersError,
  clearUserError,
  updateUser,
  updateUserResponse,
  updateUserError,
  deleteUser,
  deleteUserResponse,
  fetchUsers,
  fetchInviteCompany,
  fetchInviteCompanyResponse,
  fetchInviteCompanyError,
  addNewInvite,
  addNewInviteResponse,
  addNewInviteLoading,
  inviteUsers,
  getCurrentAgencyError,
  setCurrentAgency,
  getCurrentAgencyLoading,
  getCurrentAgency } from "store/modules/users/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import { getCurrentUserId } from "store/modules/users/selectors";
import { getToken } from "store/modules/auth/selectors";


import { updateProfileAgencyInfoResponse } from "store/modules/profile/actions";
import { fetchCompaniesForTable } from "store/modules/companies/actions";
import { getProfileInfo } from "store/modules/profile/selectors";
import { getDashboardOnboarding } from "store/modules/dashboard/selectors";
import useVerificationStore from "store/verificationStore.ts";
import {
  getRequestWithToken,
  putRequestWithToken,
  postRequestWithToken,
  deleteRequestWithToken,
  getRequest,
  postRequest
} from "./api";
import { handleOnboardingWorker } from "./widgets";

export function* getCurrentAgencyRelationWorker({ payload: {
  agencyKey
} = {} }) {
  try {
    const token = yield select(getToken);

    const { data } = yield getRequestWithToken({
      url: `/api/agency/${agencyKey}/user-relation/`,
      token
    });

    return data;
  } catch (err) {
    return {};
  }
}

export function* getCurrentAgencyPlanWorker({ payload: {
  agencyKey
} = {} }) {
  try {
    const token = yield select(getToken);

    const { data } = yield getRequestWithToken({
      url: `/api/agency/${agencyKey}/plan-info/`,
      token
    });

    return data;
  } catch (err) {
    return null;
  }
}

export function* getEntireCurrentAgencyInfoWorker({ payload: {
  agencyKey
} = {} }) {
  try {
    yield put(getCurrentAgencyLoading(true));

    const token = yield select(getToken);
    const profileInfo = yield select(getProfileInfo);

    const { data } = yield getRequestWithToken({
      url: `/api/agency/${agencyKey}/`,
      token
    });


    const profileAgencyInfo = {
      agency: data.key,
      agency_name: data.name
    };

    let agencyPlan = {};
    let userAgency = {};

    yield put(updateProfileAgencyInfoResponse(profileAgencyInfo));

    if (profileInfo?.is_verified) {
      useVerificationStore.getState().fetchVerificationStatus(data.key);

      const onboarding = yield select(getDashboardOnboarding);

      if (!onboarding.length) {
        yield call(handleOnboardingWorker, { payload: { key: data?.key } });
      }

      agencyPlan = yield call(getCurrentAgencyPlanWorker, { payload: { agencyKey } });
      userAgency = yield call(getCurrentAgencyRelationWorker, { payload: { agencyKey } });
      yield put(fetchCompaniesForTable({ key: data?.key }));
    }

    const currentAgency = { ...data, ...agencyPlan, ...userAgency };

    yield put(setCurrentAgency(currentAgency));
  } catch (error) {
    yield put(getCurrentAgencyError(error));
  } finally {
    yield put(getCurrentAgencyLoading(false));
  }
}

// eslint-disable-next-line consistent-return
export function* fetchUsersWorker() {
  try {
    yield put(isUsersLoading(true));
    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: "/api/agency/list/",
      token
    });

    yield put(fetchUsersResponse(response.data));
    return response;
  } catch (error) {
    yield put(fetchUsersError("There is something going wrong"));
    console.log("[fetchUsersListError]", error);
  } finally {
    yield put(isUsersLoading(false));
  }
}

export function* getCurrentUserWorker({ payload = {} }) {
  const responseUsers = yield call(fetchUsersWorker);
  let currentUser = responseUsers.data[0];

  if (payload?.currentUser?.key) {
    const newCurrentUser =
    responseUsers?.data?.filter(user => user.key === payload?.currentUser?.key)?.[0];
    if (newCurrentUser) currentUser = newCurrentUser;
  }

  if (!currentUser) return;
  yield call(getEntireCurrentAgencyInfoWorker, { payload: { agencyKey: currentUser?.key } });

  // eslint-disable-next-line consistent-return
  return currentUser;
}

// eslint-disable-next-line consistent-return
export function* fetchUsersListWorker({ payload = {} }) {
  try {
    yield put(isUsersLoading(true));
    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: `/api/agency/${payload}/invites/`,
      token
    });

    yield put(fetchUsersListResponse(response.data));
    return response.data;
  } catch (error) {
    yield put(fetchUsersListError("There is something going wrong"));
    console.log("[fetchUsersError]", error);
  } finally {
    yield put(isUsersLoading(false));
  }
}

export function* addNewUsersWorker({ payload: {
  invites = [],
  callback
} = {} }) {
  try {
    yield put(isUsersLoading(true));
    yield put(clearUserError());
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);
    const response = yield postRequestWithToken({
      url: "/api/agency/invite/",
      token,
      payload: {
        agency: agencyKey,
        invites
      }
    });

    yield put(addNewUsersResponse(response.data));
    yield put(setIsUsersAdded(true));
    if (typeof callback === "function") callback();
  } catch (error) {
    // if response status is 400 then there might be backend
    // validation errors so we must show them to user;
    if (error?.response?.status === 400) {
      const { data } = error.response;
      const err = Object.keys(data).reduce((acc, f) => {
        if (data[f].length && !acc) {
          // eslint-disable-next-line prefer-destructuring
          acc = typeof data[f] === "string" ? data[f] : data[f][0];
        }
        return acc;
      }, null);

      yield put(addNewUsersError(err));
    } else {
      yield put(addNewUsersError("There is something going wrong"));
    }

    console.log("[addNewUsersRequestError]", error);
  } finally {
    yield put(isUsersLoading(false));
  }
}

export function* inviteUsersWorker({ payload: {
  invites = [],
  callback
} = {} }) {
  try {
    yield put(isUsersLoading(true));
    yield put(clearUserError());
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);
    const response = yield postRequestWithToken({
      url: "/api/agency/invite/",
      token,
      payload: {
        agency: agencyKey,
        invites
      }
    });

    yield put(addNewUsersResponse(response.data));
    yield put(setIsUsersAdded(true));

    if (typeof callback === "function") callback(response.data);
  } catch (error) {
    // if response status is 400 then there might be backend
    // validation errors so we must show them to user;
    if (error?.response?.status === 400) {
      const { data } = error.response;
      const err = Object.keys(data).reduce((acc, f) => {
        if (data[f].length && !acc) {
          // eslint-disable-next-line prefer-destructuring
          acc = typeof data[f] === "string" ? data[f] : data[f][0];
        }
        return acc;
      }, null);

      yield put(addNewUsersError(err));
    } else {
      yield put(addNewUsersError("There is something going wrong"));
    }

    console.log("[addNewUsersRequestError]", error);
  } finally {
    yield put(isUsersLoading(false));
  }
}

export function* updateUserWorker({ payload = {} }) {
  try {
    yield put(isUsersLoading(true));
    const token = yield select(getToken);

    const { fullName, phone, role, key } = payload;

    const updatedUserInfo = {
      full_name: fullName,
      role,
      phone
    };

    const response = yield putRequestWithToken({
      url: `/api/agency/invite/${key}/update/`,
      token,
      payload: updatedUserInfo
    });

    if (response.status === 200) {
      yield put(
        updateUserResponse({
          key,
          ...response.data
        })
      );
    }

    yield put(updateUserResponse(response.data));
  } catch (error) {
    yield put(updateUserError("Cant update user info"));
    console.log("[updateRequestError]", error);
  } finally {
    yield put(isUsersLoading(false));
  }
}

export function* deleteUserWorker({ payload = {} }) {
  try {
    yield put(isUsersLoading(true));
    const token = yield select(getToken);
    yield deleteRequestWithToken({
      url: `/api/agency/invite/${payload}/delete/`,
      token
    });
    yield put(deleteUserResponse(payload));
  } catch (error) {
    console.log("[deleteRequestError]", error);
  } finally {
    yield put(isUsersLoading(false));
  }
}

export function* fetchInviteCompanyWorker({ payload }) {
  try {
    yield put(setLoadingScreen(true));
    yield put(fetchInviteCompanyResponse(null));
    const response = yield getRequest({
      url: "/api/agency/generic-invite/",
      headers: { authorization: payload }
    });

    yield put(fetchInviteCompanyResponse(response.data));
  } catch (error) {
    yield put(fetchInviteCompanyError("There is something going wrong"));
    console.log("[fetchUsersError]", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* addNewInviteWorker({ payload: {
  token,
  agencyKey,
  email,
  role
} = {} }) {
  try {
    yield put(addNewInviteLoading(true));
    yield put(clearUserError());
    const response = yield postRequest({
      url: "/api/agency/generic-invite/",
      headers: { authorization: token },
      payload: {
        agency: agencyKey,
        invites: [
          { email, role }
        ]
      }
    });
    yield put(addNewInviteResponse(response.data));
  } catch (error) {
    if (error?.response?.status === 400) {
      const { data } = error.response;
      const err = Object.keys(data).reduce((acc, f) => {
        if (data[f].length && !acc) {
          // eslint-disable-next-line prefer-destructuring
          acc = typeof data[f] === "string" ? data[f] : data[f][0];
        }
        return acc;
      }, null);

      yield put(addNewUsersError(err));
    } else {
      yield put(addNewUsersError("There is something going wrong"));
    }

    console.log("[addNewUsersRequestError]", error);
  } finally {
    yield put(addNewInviteLoading(false));
  }
}

export function* createUserwWatcher() {
  yield takeEvery(updateUser, updateUserWorker);
  yield takeEvery(deleteUser, deleteUserWorker);
  yield takeEvery(fetchUsersList, fetchUsersListWorker);
  yield takeEvery(fetchUsers, fetchUsersWorker);
  yield takeEvery(addNewUsers, addNewUsersWorker);
  yield takeEvery(inviteUsers, inviteUsersWorker);
  yield takeEvery(fetchInviteCompany, fetchInviteCompanyWorker);
  yield takeEvery(addNewInvite, addNewInviteWorker);
  yield takeEvery(getCurrentAgency, getEntireCurrentAgencyInfoWorker);
}

export default createUserwWatcher;
