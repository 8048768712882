/* eslint-disable import/no-cycle */
import { takeEvery, call, select, put } from "redux-saga/effects";

import {
  handleOnboarding,
  handleOnboardingError,
  handleOnboardingResponse,
  refreshStatus,
  saveIsOnline,
  saveRefreshRoute,
  setHealthCheckError,
  setLoadingScreenMaintenance
} from "store/modules/widgets/actions";
import { getHealthcheckError, getIsOnline, getRefreshRoute } from "store/modules/widgets/selectors";
import { history, store } from "store";

import { getToken } from "store/modules/auth/selectors";

import { isEmpty, isNull } from "lodash";
import { getCandidateToken } from "store/modules/сandidates/selectors";
import { isCurrentUserCandidate } from "mixins/helperCandidate";
import { getFullUrlRequestWithToken, getRequest, getRequestWithToken } from "./api";


export function* handleOnboardingWorker({ payload: { key } }) {
  try {
    const token = yield select(getToken);

    if (key && token) {
      const response = yield call(getRequestWithToken, {
        token,
        url: `/api/agency/${key}/dashboard/`
      });

      yield put(handleOnboardingResponse(response.data));
    }
  } catch (error) {
    yield put(handleOnboardingError("Could not get data for onboarding"));
  }
}

// eslint-disable-next-line consistent-return
export function* refreshStatusWorker() {
  try {
    yield put(setLoadingScreenMaintenance(true));
    const route = yield select(getRefreshRoute);
    const { pathname } = window.location;

    const recruiterToken = yield select(getToken);
    const candidateToken = yield select(getCandidateToken);

    const appToken = isCurrentUserCandidate ? candidateToken : recruiterToken;
    let response;
    const isMaintenanceTestEnabled = !isEmpty(process.env.REACT_APP_API_TEST_URL);

    const endpoint = isMaintenanceTestEnabled
      ? `${process.env.REACT_APP_API_TEST_URL}/api/healthcheck/`
      : "/api/healthcheck/";

    if (appToken) {
      response = yield isMaintenanceTestEnabled
        ? getFullUrlRequestWithToken({ fullUrl: endpoint, token: appToken })
        : yield getRequestWithToken({
          url: endpoint,
          token: appToken
        });
    } else {
      response = yield getRequest(
        isMaintenanceTestEnabled
          ? { fullUrl: endpoint } : { url: endpoint }
      );
    }

    const isOnline = yield select(getIsOnline);

    if (response.status === 200) {
      if (!isOnline) yield put(saveIsOnline(true));

      if (pathname === "/maintenance") {
        yield put(saveRefreshRoute(null));
        history.push(route || "/dashboard");
      }
    } else if (pathname !== "/maintenance") {
      yield put(saveRefreshRoute({
        pathname: window.location.pathname,
        search: window.location.search
      }));
      history.push("/maintenance");
    }
  } catch (error) {
    console.log("[refreshStatusError]", error);

    const { pathname } = window.location;

    const healthcheckError = getHealthcheckError(store.getState());

    const isOnline = yield select(getIsOnline);
    const isServerError = [500, 502, 503, 504, 521, 522, 523].includes(error?.response?.status);
    const isNotOnMaintenancePage = pathname !== "/maintenance";
    const shouldRedirect = isCurrentUserCandidate || isOnline;

    if (!isNull(healthcheckError) && !error?.message?.includes("Network Error")) {
      store.dispatch(setHealthCheckError(null));
    }

    if (isServerError && isNotOnMaintenancePage && shouldRedirect) {
      yield put(saveRefreshRoute({
        pathname: window.location.pathname,
        search: window.location.search
      }));

      return history.push("/maintenance");
    }
  } finally {
    yield put(setLoadingScreenMaintenance(false));
  }
}

export function* widgetwWatcher() {
  yield takeEvery(handleOnboarding, handleOnboardingWorker);
  yield takeEvery(refreshStatus, refreshStatusWorker);
}

export default widgetwWatcher;
