import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Navbar, Nav } from "react-bootstrap";

import Hamburger from "components/Common/Hamburger";
import Menu from "components/Common/Header/Main/Menu";
import logo from "assets/images/icons/logo-blue.png";

import { getIsMeterSubscriptionUnpaid } from "store/modules/notifications/selectors";
import { store } from "store";
import TooltipSuccess from "components/Common/Tooltip/Success";
import { useAuth0 } from "@auth0/auth0-react";
import useWindowResize from "hooks/useWindowResize.ts";
import useVerificationStore from "store/verificationStore.ts";
import { StripeVerificationStatus } from "constants/stripe.ts";

import PersonaButtonWrapper from "components/PersonaVerification/PersonaButtonWrapper.tsx";
import UserSelect from "./UserSelect";

const Main = ({
  isAuthenticated,
  isAdmin,
  isFree,
  profileInfo,
  currentUser,
  usersList,
  fetchJobs,
  setCurrentUser,
  handleOnboarding,
  history,
  logOut,
  isTrialCompleted = false,
  isTrialPeriod = false,
  isSubscriptionFailed = false,
  showUpgrade = true,
  isSetupCompleted,
  isTrialOrPaid,
  getCurrentAgency,
  fetchProfileInfo,
  token,
  refreshToken,
  isAccountVerified,
  isMfaRequired,
  isEmailVerified
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const itemRef = useRef(null);
  const { logout: logoutAuth0, isAuthenticated: isAuth0Authenticated } = useAuth0();
  const { fetchVerificationStatus, isLoading, verificationStatus } = useVerificationStore();
  const { isMobileScreen } = useWindowResize();
  const agencyKey = currentUser?.key;
  const isMeteredSubscriptionUnpaid = getIsMeterSubscriptionUnpaid(
    store.getState()
  );

  const logOutUser = async () => {
    if (isAuth0Authenticated) {
      await logoutAuth0({
        logoutParams: {
          returnTo: `${window.location.origin}/sign-in`
        },
        openUrl: false
      });
    }

    await logOut({ refreshToken, token });
  };

  useEffect(() => {
    if (verificationStatus || isLoading || !isAuthenticated ||
      !isEmailVerified || process.env.REACT_APP_PERSONA_ENABLED !== "true" || !agencyKey) return;
    fetchVerificationStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, verificationStatus, isAuthenticated, isEmailVerified, agencyKey]);

  const isStripeVerificationFailed = verificationStatus === StripeVerificationStatus.FAILED;

  const isAppEnabled =
    !isTrialCompleted && !isSubscriptionFailed && isSetupCompleted
    && isAccountVerified && !isStripeVerificationFailed && !isMfaRequired && isEmailVerified;

  const isUpgradeButtonVisible =
    isAdmin &&
    (isFree || isTrialPeriod) &&
    showUpgrade &&
    isSetupCompleted &&
    !isMeteredSubscriptionUnpaid &&
    !isMfaRequired && !isStripeVerificationFailed;

  let header = (
    <div className="header">
      <Container fluid={isMobileScreen}>
        <Navbar>
          <Navbar.Brand>
            <img src={logo} alt="logo" className="header__logo" />
          </Navbar.Brand>
        </Navbar>
      </Container>
    </div>
  );


  const onToggle = bool => {
    setOpen(bool);
  };

  if (isAuthenticated) {
    header = (
      <div className="header">
        <Container>
          <Navbar expand="md" onToggle={onToggle}>
            <Navbar.Brand>
              {isAppEnabled ? (
                <Link to="/dashboard">
                  <img src={logo} alt="logo" className="header__logo" />
                </Link>
              ) : (
                <img src={logo} alt="logo" className="header__logo" />
              )}
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Hamburger isOpen={isOpen} />
            </Navbar.Toggle>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {isAppEnabled && (
                  <>
                    <Nav.Item as="li">
                      <NavLink
                        to="/jobs"
                        id="nav-jobs"
                        className="nav-notification"
                      >
                        <span>
                          {t("header.jobs")}
                          {currentUser.new_candidates ? (
                            <div className="nav-badge">
                              {currentUser.new_candidates}
                            </div>
                          ) : null}
                        </span>
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <NavLink to="/people" id="nav-people">
                        {t("header.people")}
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <NavLink to="/companies" id="nav-companies">
                        {t("header.companies")}
                      </NavLink>
                    </Nav.Item>

                    <Nav.Item as="li">
                      <NavLink
                        to="/integrations"
                        id="nav-connect"
                      >
                        <span
                          ref={itemRef}
                          onPointerEnter={() => setShowTooltip(true)}
                          onPointerLeave={() => setShowTooltip(false)}
                        >
                          {t("header.integrations")}
                        </span>
                      </NavLink>
                    </Nav.Item>

                    <TooltipSuccess
                      position="bottom"
                      message={t("marketplace.navHeader.tooltip")}
                      target={itemRef.current}
                      show={showTooltip}
                    />
                  </>
                )}

                {isMobileScreen && (
                  <UserSelect
                    setCurrentUser={setCurrentUser}
                    handleOnboarding={handleOnboarding}
                    usersList={usersList}
                    currentUser={currentUser}
                    isAdmin={isAdmin}
                    logOutUser={logOutUser}
                    isAppEnabled={isAppEnabled}
                    isSubscriptionFailed={isSubscriptionFailed}
                    fullName={profileInfo.full_name}
                    isSetupCompleted={isSetupCompleted}
                    isTrialOrPaid={isTrialOrPaid}
                    getCurrentAgency={getCurrentAgency}
                    fetchProfileInfo={fetchProfileInfo}
                    isAccountVerified={isAccountVerified}
                    isMfaRequired={isMfaRequired}
                  />
                )}
              </Nav>

              {isUpgradeButtonVisible && (
                <PersonaButtonWrapper>
                  <Link
                    to="/plans?utm_source=internal&utm_medium=user&utm_campaign=header_upgrade"
                    className="header__upgrade-button"
                  >
                    <button
                      type="button"
                      className="n-button__medium n-bg-purple-100 n-white button__menu"
                    >
                      {t("button.upgrade")}
                    </button>
                  </Link>
                </PersonaButtonWrapper>
              )}

              <Menu
                history={history}
                handleOnboarding={handleOnboarding}
                setCurrentUser={setCurrentUser}
                profileInfo={profileInfo}
                currentUser={currentUser}
                usersList={usersList}
                logOutUser={logOutUser}
                fetchJobs={fetchJobs}
                isAdmin={isAdmin}
                isAppEnabled={isAppEnabled}
                isSubscriptionFailed={isSubscriptionFailed}
                isSetupCompleted={isSetupCompleted}
                isTrialOrPaid={isTrialOrPaid}
                getCurrentAgency={getCurrentAgency}
                fetchProfileInfo={fetchProfileInfo}
                isAccountVerified={isAccountVerified}
                isMfaRequired={isMfaRequired}
              />
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }

  return header;
};

Main.defaultProps = {
  history: undefined,
  isAuthenticated: false,
  isFree: false,
  logOut: undefined,
  fetchJobs: undefined,
  getCurrentAgency: undefined,
  fetchProfileInfo: undefined,
  refreshToken: undefined,
  token: undefined,
  isAccountVerified: false,
  isMfaRequired: false
};

Main.propTypes = {
  showUpgrade: PropTypes.bool,
  isSetupCompleted: PropTypes.bool,
  history: PropTypes.shape({}),
  isAuthenticated: PropTypes.bool,
  isFree: PropTypes.bool,
  logOut: PropTypes.func,
  fetchJobs: PropTypes.func,
  getCurrentAgency: PropTypes.func,
  fetchProfileInfo: PropTypes.func,
  refreshToken: PropTypes.string,
  token: PropTypes.string,
  isAccountVerified: PropTypes.bool,
  isMfaRequired: PropTypes.bool
};


export default Main;
